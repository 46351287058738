<template>
	<router-view />
</template>
<script setup>
	const debounce = (fn, delay) => {
		let timer = null;
		return function() {
			let context = this;
			let args = arguments;
			clearTimeout(timer);
			timer = setTimeout(function() {
				fn.apply(context, args);
			}, delay);
		}
	}

	const _ResizeObserver = window.ResizeObserver;
	window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
		constructor(callback) {
			callback = debounce(callback, 16);
			super(callback);
		}
	}
</script>
<style lang="scss">
	@font-face {
		font-family: robot; // 自定义名称，无需引号
		src: url('@/assets/ttf/title.ttf') format('truetype') // 字体文件路径，支持本地文件，或字体文件链接地址
	}

	* {
		margin: 0;
		padding: 0;
		font-family: robot;
	}

	::v-deep .el-popper__arrow {

		display: none !important;
	}



	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		position: absolute;
	}

	::-webkit-scrollbar-thumb {
		background: #1890ff;
	}

	::-webkit-scrollbar-track {
		background: #ddd;
	}
</style>