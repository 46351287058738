<template>

	<el-container style="padding: 0 !important;">
		<el-header class="header" :height="124">

			<img id="logo" src="@/assets/images/index/logo.png"></img>
			<el-menu id="menu" :default-active="activeIndex" :active-name="activeMenu" background-color="#064793"
				class="el-menu-demo" mode="horizontal" :ellipsis="ellipsis" text-color="#FFF"
				active-text-color="#6bb392" router @select="handleSelect">


				<el-menu-item index="0">

				</el-menu-item>
				<div class="flex-grow" />
				<el-menu-item index="/home" name="home">首页</el-menu-item>
				<el-menu-item index="/about" name="about">关于望墨</el-menu-item>
				<el-menu-item index="/product" name="product">产品中心</el-menu-item>

				<el-menu-item index="/service" name="service">服务支持</el-menu-item>
				<!-- 	<el-menu-item index="/project">行业案例</el-menu-item> -->
				<el-menu-item index="/news" name="news">新闻资讯</el-menu-item>
				<el-menu-item index="/contact" name="contact">
					联系我们
				</el-menu-item>
				<div class="flex-grow-2" />
				<!-- 	<el-menu-item index="7">

				</el-menu-item> -->

			</el-menu>

		</el-header>
		<el-main>
			<transition name="el-zoom-in-center" appear>
				<router-view :promenulist="promenulist" :searchinput="searchinput"
					@updateActive="updateActive"></router-view>
			</transition>
		</el-main>

	</el-container>


</template>

<script setup name="Index">
	import {
		ref,
		onBeforeMount,
		inject,
		watch,
		onMounted,
		onUnmounted,
		onBeforeUnmount,
		nextTick,
		reactive
	} from 'vue'

	import {
		useRouter,
		useRoute
	} from 'vue-router';
	const route = useRouter();
	import {
		Search
	} from '@element-plus/icons-vue'
	import $ from 'jquery'



	//const   img = require("@/assets/navimg/chaoshi.png")
	const activeIndex = ref('/home')
	const promenulist = ref([])
	const searchinput = ref('')
	const searchinputRef = ref()
	const activeMenu = ref('home')
	const ellipsis = ref(false)
	const headH = ref(124)


	const updateActive = (msg) => {



		activeIndex.value = "/" + msg


	}

	// watch(msg, (newValue,oldValue)=>{



	// })


	const shearch = () => {

		//跳转产品列表
		if (route.currentRoute.value.path != '/product') {

			route.push({
				name: 'product',
				query: {
					searchKey: searchinput.value
				}
			})

		}
	}

	const handleSelect = (key, keyPath) => {
		activeIndex.value = key;
	}

	onMounted(() => {

		window.addEventListener('resize', handleResize);

		nextTick(() => {




			handleResize()

		})



	});

	const handleResize = () => {

		//let w = document.documentElement.clientWidth;
		//let rate = w / 2560;
		//$("#wmgw").css('transform', `scale(${rate})`);
		let bgW = $(".header").width();
		console.log(bgW)

		headH.value = $(".el-menu-demo").height()
		$('.el-header').css("height", '124px')
		$('.el-main').css("margin-top", '124px')

		if (bgW >= 1540) {

			$(".flex-grow-2").css("width", `348px`)

			$("#logo").css("left", `348px`);
			$("#logo").show()
		} else if (bgW >= 1340 && bgW < 1540) {

			$(".flex-grow-2").css("width", `148px`)

			$("#logo").css("left", `148px`);
			$("#logo").show()
		} else if (bgW >= 1140 && bgW < 1340) {

			$(".flex-grow-2").css("width", `5px`)

			$("#logo").css("left", `5px`);
			$("#logo").show()
		} else if (bgW >= 920 && bgW < 1140) {

			$(".flex-grow-2").css("width", `0px`)

			$("#logo").css("left", `0px`);
			$("#logo").show()
		} else {


			$("#logo").hide()

		}


		if (bgW < 750) {
			ellipsis.value = true
		} else {
			ellipsis.value = false
		}
		resetFontSize()


	}

	function resetFontSize() {

		let size = 27;
		if (document.documentElement.clientWidth > 800 && document.documentElement.clientWidth < 1200) {
			size = 26;
		} else if (document.documentElement.clientWidth >= 1200) {

			size = 27;
		} else {
			size = 27;


		}

		document.documentElement.style.fontSize = size + 'px';
	}

	onUnmounted(() => {
		window.removeEventListener('resize', handleResize);


	});

	onBeforeUnmount(() => {

		window.removeEventListener('resize', handleResize);



	})



	onBeforeMount(() => {
		console.log('onBeforeMount-------------------')

	})
</script>


<style scoped>
	@font-face {
		font-family: robotmenu;
		src: url('@/assets/ttf/menu.ttf') format('truetype')
	}

	* {
		margin: 0;
		padding: 0;
		font-family: robotmenu;
	}



	.header {
		position: absolute;
		width: 100%;
		left: 0;
		z-index: 22;


	}

	.el-header {
		--el-header-padding: 0;
		--el-menu-hover-text-color: #6bb392;
		opacity: 1;
		width: 100%;
		margin-top: 0;

	}

	.el-main {
		--el-main-padding: 0px;
		margin-top: 124px;

	}

	::v-deep(.el-menu-item:hover) {

		color: #6bb392 !important;
	}


	::v-deep(.el-menu--horizontal) {

		border-bottom: none !important;

	}


	.el-menu-demo {

		background-image: url('@/assets/images/index/head.png');
		height: -webkit-fill-available;
		width: 100%;
		object-fit: cover;
		background-size: cover;
		background-repeat: repeat-x;
	}

	#menu {

		background-image: url('@/assets/images/index/head.png');
		height: -webkit-fill-available;
		width: 100%;
		object-fit: cover;
		background-size: cover;
		background-repeat: repeat-x;

	}

	#logo {

		position: absolute;
		z-index: 999;
		height: 45px;
		left: 10px;
		top: 40px;
		line-height: 124px;

	}


	::v-deep(.el-menu-item) {

		font-size: 0.8rem !important;
	}

	.flex-grow {
		flex-grow: 1;
	}

	.flex-grow-2 {
		width: 348px;
	}

	.flex-grow-search {

		width: 50px;
		line-height: 50px;
	}

	.logobox {
		width: 100%;
		height: 80px;
		display: flex;
		justify-content: space-around;
		align-items: center;

	}




	.inputcontainer input::placeholder {
		color: white;
		opacity: 0.5;
		font-weight: bolder;
	}


	li {
		display: inline-block;
		position: relative;
		padding: 0 20px;
	}

	li::before {

		content: "";

		position: absolute;

		top: 0;

		left: 100%;

		width: 0;

		height: 100%;

		border-bottom: 2px solid #1b2a65;

		transition: 0.3s all linear;

	}

	li:hover::before {

		width: 100%;

		left: 0;

	}

	li:hover~li::before {
		left: 0;
	}

	::v-deep .el-main {
		height: 100vh;
	}
</style>