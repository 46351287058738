import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "animate.css/animate.min.css";

import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'




createApp(App).use(ElementPlus).use(store).use(router).use(VueVideoPlayer).mount('#app')