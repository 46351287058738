import {
	createRouter,
	createWebHashHistory,
	createWebHistory
} from 'vue-router'


import Layout from "@/layout/index";
//进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
const routes = [

	{
		path: '/',
		name: '首页',
		component: Layout,
		hidden: true,
		children: [{
				path: "/",
				name: "",
				component: () => import('@/views/Home.vue'),
				meta: {
					text: "首页",
					defaultVisited: true,
				},
			},
			{
				path: "/home",
				name: "home",
				component: () => import('@/views/Home.vue'),
				meta: {
					text: "首页",
					defaultVisited: true,
				},
			},

			{
				path: "/about",
				name: "about",
				component:  () => import('@/views/About.vue'),
				meta: {
					text: "关于我们",
					defaultVisited: true,
				},
			},
			{
				path: "/contact",
				name: "contact",
				component: () => import('@/views/Contact.vue'),
				meta: {
					text: "联系我们",
					defaultVisited: true,
				},
			},
			{
				path: "/product",
				name: "product",
				component: () => import('@/views/Product.vue'),
				meta: {
					text: "产品中心",
					defaultVisited: true,
				},
			},
			// {
			// 	path: "/productDetail",
			// 	name: "productDetail",
			// 	component: () => require('@/views/ProductDetail.vue'),
			// 	meta: {
			// 		text: "产品详情",
			// 		defaultVisited: true,
			// 	},
			// },
			// {
			// 	path: "/project",
			// 	name: "project",
			// 	component: () => require('@/views/Project.vue'),
			// 	meta: {
			// 		text: "工程案例",
			// 		defaultVisited: true,
			// 	},
			// },
			{
				path: "/service",
				name: "Service",
				component: () => import('@/views/Service.vue'),
				meta: {
					text: "服务支持",
					defaultVisited: true,
				},
			},
			// {
			// 	path: "/projectDetail",
			// 	name: "projectDetail",
			// 	component: () => require('@/views/ProjectDetail.vue'),
			// 	meta: {
			// 		text: "工程详情",
			// 		defaultVisited: true,
			// 	},
			// },
			{
				path: "/news",
				name: "news",
				component:  () => import('@/views/News.vue'),
				meta: {
					text: "新闻资讯",
					defaultVisited: true,
				},
			}

		]
	}


]

const router = createRouter({
	history: createWebHistory(),
	routes
})

//router.beforeEach((to, from, next) => {
//NProgress.start();
//	next()
//})
//router.afterEach((to, from, next) => {
//NProgress.done();
//})

export default router